import React, { useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa'
import { TypeAnimation } from 'react-type-animation';
import { Link } from 'react-scroll';

const Header = () => {

    const [nav, setNav] = useState(false);
    const handleClick = () => setNav(!nav);
    return (
        <div className='fixed w-full h-20 flex items-center justify-between px-3 bg-[#333f63] z-10 text-[#d7d4dd] '>
            <div>
                <h1 className='text-4xl max-sm:text-2xl '>

                    <TypeAnimation
                        sequence={['▯leks', 500, 'Oleksandr => {Olex}']}
                        wrapper="h2"
                        speed={1}
                        style={{ fontFamily: 'Chakra Petch' }}
                    />
                </h1>

            </div>

            <div>
                <ul className=' hidden md:flex'>
                    <li>
                        <Link onClick={handleClick} to='home' smooth={true} duration={650}>
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link onClick={handleClick} to='skills' smooth={true} duration={650}>
                            Skills
                        </Link>
                    </li>
                    <li>
                        <Link onClick={handleClick} to='about' smooth={true} duration={650}>
                            About[ME]
                        </Link>
                    </li>
                </ul>

                <div onClick={handleClick} className='md:hidden z-10'>
                    {!nav ? <FaBars /> : <FaTimes />}


                    {/* Mobile menu */}
                    <ul
                        className={
                            !nav
                                ? 'hidden'
                                : 'absolute top-0 left-0 w-screen h-screen overflow-hidden bg-[#0a1d36] flex flex-col justify-center items-center'
                        }
                    >
                        <li className='py-6 text-4xl'>
                            <Link onClick={handleClick} to='home' smooth={true} duration={500}>
                                Home
                            </Link>
                        </li>
                        <li className='py-6 text-4xl'>
                            {' '}
                            <Link onClick={handleClick} to='skills' smooth={true} duration={500}>
                                Skills
                            </Link>
                        </li>
                        <li className='py-6 text-4xl'>
                            {' '}
                            <Link onClick={handleClick} to='about' smooth={true} duration={500}>
                                About
                            </Link>
                        </li>
                    </ul>

                </div>
            </div>
        </div>
    )
}

export default Header
import React from 'react'
import { FaLinkedin, FaMailBulk, FaMailchimp } from 'react-icons/fa'
import { HiOutlineMail } from 'react-icons/hi'
import {BsFillPersonLinesFill} from 'react-icons/bs'
function Footer() {
  return (
    <div className='fixed w-full bottom-0 h-16 flex items-center justify-between px-4 bg-[#333f63] text-[#d7d4dd]'>
        <h1 style={{fontFamily: 'Chakra Petch'}}>COPYRIGHT © 2023 OLEKSANDR - ALL RIGHTS RESERVED. </h1>
    <ul className='flex'>
        <li>
            <a href='https://www.linkedin.com/in/oleksandr-oliynyk-623497110/'>
             <FaLinkedin size={30}/>  
            </a>
        </li>
    </ul>

    </div>
  );
}

export default Footer
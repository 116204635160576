import React from 'react'

function About() {
    return (
        <div name='about' className=' w-screen py-60 bg-[#434868] text-gray-400 left-0'>
            <div>
                <div className='w-screen gap-8'>
                     <hr class='h-1 border-0 bg-[#6d54fd] w-auto'></hr>
                    <div className='pb-8 pl-4'>
                        <p className='text-4xl font-bold text-center pt-4 text-[#d7d4dd] border-gray-300'>About</p>
                    </div>
                    <div className='text-center w-screen gap-8 px-6 flex justify-center text-[#e6e3eb]  '>
                        <div className='text-xl font-thin mt-2 '>
                            <p>I'm a passionate programmer with 3 years of experience in the IT.  </p>
                            <p>I've worked on a variety of projects, from small web apps to large-scale enterprise systems. Mostrly where related with AI and process automation, eventougth my secret passion is web development</p>
                            <p>Two of my strengths poits are ability to work collaboratively with others and knowledge of multiple languages, such as:</p>
                            <p className='mt-2'>- Portuguese (Native)</p>
                            <p>- Ukrainian (Native)</p>
                            <p>- Russian (Native)</p>
                            <p>- English (C2)</p>
                            <p>- Spanish (B1)</p>
                            <p>- Italian (A1)</p>
                            <p className='my-4'>In the end, you're looking for a passionate, collaborative, and skilled programmer to join your team, I'd love to hear from you!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About
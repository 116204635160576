import React from 'react'

import KC from '../Certificates/KC.webp'
import KTA from '../Certificates/KTA.webp'
import KTACC from '../Certificates/KTACC.webp'
import KTAPO from '../Certificates/KTAPO.webp'
import KTM from '../Certificates/KTM.webp'
import RPA from '../Certificates/RPA.webp'

import CS from '../Skills/cs.png'
import CSS from '../Skills/css.png'
import GIT from '../Skills/git.png'
import HTML from '../Skills/html.png'
import JS from '../Skills/js.png'
import JSON from '../Skills/json.png'
import SQL from '../Skills/sql.png'
import XML from '../Skills/xml.png'




function Certificates() {
    return (
        <div name='skills' className='bg-[#434868] w-screen '>
            <div className='mx-auto p-4 flex-col justify-center w-screen grid grid-cols-2  max-sm:grid-cols-1 '>
                <div>
                    <p className='text-4xl font-bold inline text-[#d7d4dd]'>Kofax Certificates</p>
                    <p className='py-4'></p>
                    <div className='w-full grid grid-cols-2 gap-4 text-center max-sm:grid-cols-1 '>
                    <div className='shadow-md shadow-gray-800 hover:scale-105 duration-500'>
                        <img className='mx-auto w-max' src={KTA} alt="KTA" />
                    </div>
                    <div className='shadow-md shadow-gray-800 hover:scale-105 duration-500'>
                        <img className='mx-auto w-max' src={KC} alt="KC" />
                    </div>
                    <div className='shadow-md shadow-gray-800 hover:scale-105 duration-500'>
                        <img className='mx-auto w-max' src={KTACC} alt="KTACC" />
                    </div>
                    <div className='shadow-md shadow-gray-800 hover:scale-105 duration-500'>
                        <img className='mx-auto w-max' src={KTAPO} alt="KTAPO" />
                    </div>
                    <div className='shadow-md shadow-gray-800 hover:scale-105 duration-500'>
                        <img className='mx-auto w-max' src={KTM} alt="KTM" />
                    </div>
                    <div className='shadow-md shadow-gray-800 hover:scale-105 duration-500'>
                        <img className='mx-auto w-max' src={RPA} alt="RPA" />
                    </div>
                </div>
                </div>

            <div className=' mx-auto p-2 justify-center'>
                <div>
                    <p className='text-4xl font-bold inline text-[#d7d4dd]'>Skills</p>
                    <p className='py-4'></p>
                </div>

                <div className='w-full grid grid-cols-2 sm:grid-cols-2 gap-8 text-center px-4 text-white '>
                    <div className='shadow-md shadow-[#838bc2] hover:scale-105 duration-500'>
                        <img className='w-40 mx-auto' src={HTML} alt="HTML icon" />
                        <p className='my-4'>HTML</p>
                    </div>
                    <div className='shadow-md shadow-[#838bc2] hover:scale-105 duration-500'>
                        <img className='w-28 mx-auto' src={CSS} alt="HTML icon" />
                        <p className='my-4'>CSS</p>
                    </div>
                    <div className='shadow-md  shadow-[#838bc2] hover:scale-105 duration-500'>
                        <img className='w-28 mx-auto' src={JS} alt="HTML icon" />
                        <p className='my-4'>JAVASCRIPT</p>
                    </div>
                    <div className='shadow-md shadow-[#838bc2] hover:scale-105 duration-500'>
                        <img className='w-40 mx-auto' src={CS} alt="HTML icon" />
                        <p className='my-4'>C#</p>
                    </div>
                    <div className='shadow-md shadow-[#838bc2] hover:scale-105 duration-500'>
                        <img className='w-40 mx-auto' src={GIT} alt="HTML icon" />
                        <p className='my-4'>GITHUB</p>
                    </div>
                    <div className='shadow-md shadow-[#838bc2] hover:scale-105 duration-500'>
                        <img className='w-40 mx-auto' src={JSON} alt="HTML icon" />
                        <p className='my-4'>JSON</p>
                    </div>
                    <div className='shadow-md shadow-[#838bc2] hover:scale-105 duration-500'>
                        <img className='w-80 mx-auto' src={SQL} alt="HTML icon" />
                        <p className='my-4'>SQL</p>
                    </div>
                    <div className='shadow-md shadow-[#838bc2] hover:scale-105 duration-500'>
                        <img className='w-40 mx-auto' src={XML} alt="HTML icon" />
                        <p className='my-4'>XML</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    )
}

export default Certificates
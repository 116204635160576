import Footer from "./Components/Footer";
import Header from "./Components/Header";
import Home from "./Components/Home";
import About from "./Components/About";
import Certificates from "./Components/Certificates";

function App() {
  return (
    <>
    <Header/>
    <Home />
    <Certificates/>
    <About  />
    <Footer/>
    </>
  );
}

export default App;

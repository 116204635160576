import React from 'react'
import Photo from "../Certificates/photo.webp"

function Home() {
  return (
    <div name='home' className='w-screen lg:h-screen bg-[#939dda] grid grid-cols-2  max-sm:h-2/4'>
      <div className='py-52 max-sm:py-10' >
        <div className='max-w-[800px] ms-auto px-8 flex-col justify-center'>
          <h1 className=' text-6xl text-[#2f3d66] py-14 max-sm:text-2xl '>Greetings!</h1>
          <h3 className='text-gray-900 px-1 text-4xl py max-sm:text-xl'>I'm Oleksandr</h3>
          <p className=' text-[#262f4b] px-1 text-2xl font-thin max-sm:text-lg'>AKA Olex</p>
          <p className='py-4' ></p>
          <p className=' text-gray-900 px-1 text-2xl max-sm:text-lg'>As a Junior Developer, I have a background in process automation and a strong understanding of Web Development.</p>
          <p className='text-[#0040ff] px-1'> PS: I also know multiple languages, but Shhhh. </p>
        </div>
      </div>
      <div className='relative'>
        <img src={Photo} className=' absolute bottom-0 right-0 lg:w-10/12'></img>
      </div>
    </div>
  )
}

export default Home